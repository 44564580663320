define("discourse/plugins/poll/controllers/poll-breakdown", ["exports", "@ember/controller", "I18n", "discourse/mixins/modal-functionality", "@ember/object", "discourse/lib/ajax", "@ember/string", "discourse-common/utils/decorators", "@ember/template", "discourse/lib/load-script", "discourse/lib/ajax-error", "bootbox"], function (_exports, _controller, _I18n, _modalFunctionality, _object, _ajax, _string, _decorators, _template, _loadScript, _ajaxError, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    model: null,
    charts: null,
    groupedBy: null,
    highlightedOption: null,
    displayMode: "percentage",
    title(pollTitle, topicTitle) {
      return pollTitle ? (0, _template.htmlSafe)(pollTitle) : topicTitle;
    },
    groupableUserFields(fields) {
      return fields.map(field => {
        const transformed = field.split("_").filter(Boolean);
        if (transformed.length > 1) {
          transformed[0] = (0, _string.classify)(transformed[0]);
        }
        return {
          id: field,
          label: transformed.join(" ")
        };
      });
    },
    totalVotes(options) {
      return options.reduce((sum, option) => sum + option.votes, 0);
    },
    onShow() {
      this.set("charts", null);
      this.set("displayMode", "percentage");
      this.set("groupedBy", this.model.groupableUserFields[0]);
      (0, _loadScript.default)("/javascripts/Chart.min.js").then(() => (0, _loadScript.default)("/javascripts/chartjs-plugin-datalabels.min.js")).then(() => {
        this.fetchGroupedPollData();
      });
    },
    fetchGroupedPollData() {
      return (0, _ajax.ajax)("/polls/grouped_poll_results.json", {
        data: {
          post_id: this.model.post.id,
          poll_name: this.model.poll.name,
          user_field_name: this.groupedBy
        }
      }).catch(error => {
        if (error) {
          (0, _ajaxError.popupAjaxError)(error);
        } else {
          _bootbox.default.alert(_I18n.default.t("poll.error_while_fetching_voters"));
        }
      }).then(result => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.set("charts", result.grouped_results);
      });
    },
    setGrouping(value) {
      this.set("groupedBy", value);
      this.fetchGroupedPollData();
    },
    onSelectPanel(panel) {
      this.set("displayMode", panel.id);
    }
  }, [["method", "title", [(0, _decorators.default)("model.poll.title", "model.post.topic.title")]], ["method", "groupableUserFields", [(0, _decorators.default)("model.groupableUserFields")]], ["method", "totalVotes", [(0, _decorators.default)("model.poll.options")]], ["method", "setGrouping", [_object.action]], ["method", "onSelectPanel", [_object.action]]]));
});